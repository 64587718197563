import { device } from 'src/styles/breakpoints'
import { graphiteOne } from 'src/styles/newColors'
import styled from 'styled-components'

export const HeroSection = styled.section`
  a, button {
    font-weight: 700;
  }p {
    color: ${graphiteOne};
  }

  .link-mobile {
    background: none;
    border: 1px solid rgb(255, 122, 0);
    border-radius: 8px;
    height: 48px;
    width: 100%;
    color: rgb(255, 122, 0);
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .breadcrumb svg {
    width: 24px;
    height: 24px;
  }

  .breadcrumb span {
    color:  ${graphiteOne} !important;
    font-weight: 700 !important;
    font-size: 14px;

    @media ${device.tablet} {
      font-size: 16px;
    }

    @media ${device.desktopXL} {
      font-size: 18px;
    }
  }
`
