import styled from 'styled-components'

import images, { IWebpImages } from 'src/pages/pra-voce/portabilidade-de-salario/assets/images/_images'
import { device } from 'src/styles/breakpoints'

const { stillDontHaveYourInterDigitalAccount }: IWebpImages = images

export const StillDontHaveYourInterDigitalAccountSection = styled.section`
  .content-background {
    background-color: white;
    border-radius: 16px;
    padding: 32px;
  }

  background-image: url(${stillDontHaveYourInterDigitalAccount.sm});
  background-repeat: no-repeat;
  background-size: cover;
  height: 360px;

  @media ${device.tablet} {
    background-image: url(${stillDontHaveYourInterDigitalAccount.md});
    height: 474px;
  }

  @media ${device.desktopLG} {
    background-image: url(${stillDontHaveYourInterDigitalAccount.lg});
  }

  @media ${device.desktopXL} {
    background-image: url(${stillDontHaveYourInterDigitalAccount.xl});
    height: 624px;
  }

  .link-mobile {
    width: 100%;
    height: 48px;
    border: 1px solid #ff7a00;
    border-radius: 8px;
    color: #ffffff;
    background: #ff7a00;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
