import React, { useState, useLayoutEffect } from 'react'
import Button from '../../_componentes/_button/_index'
import useWidth from 'src/hooks/window/useWidth'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import * as S from './style'

const WIDTH_MD = 768

type HeroProps = {
  setIsOpenAccount: Function;
  setDataLayer: Function;
}

export const StillDontHaveYourInterDigitalAccount = ({ setIsOpenAccount, setDataLayer }: HeroProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isMobile, setIsMobile ] = useState(true)
  const width = useWidth(300)

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  const dataLayer: IDataLayerParams = {
    section: 'dobra_05',
    section_name: 'Ainda não tem sua Conta Digital Inter?',
    element_action: 'click button',
    element_name: 'Abrir conta digital',
  }

  return (
    <S.StillDontHaveYourInterDigitalAccountSection className='d-flex align-items-center'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-6 col-xl-7 content-background'>
            <h2 className='fs-24 lh-28 fs-md-30 lh-md-36 fs-xl-40 lh-xl-48 fw-700 text-grayscale--500 font-citrina text-center mb-3'>Ainda não tem sua Conta Digital Inter?</h2>
            <p className='fs-14 lh-17 fs-md-18 lh-md-22 fw-400 text-grayscale--400 font-inter text-center mb-4 pb-2'>Em poucos cliques você traz o seu salário pro Inter. É tudo no Super App e sem pagar nada. </p>
            {
              isMobile ? (
                <a
                  href='https://inter-co.onelink.me/Qyu7/lhth6zd9'
                  className='mb-3 mt-3 link-mobile'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      section_name: 'Portabilidade de Salário no Inter: a liberdade de uma conta digital gratuita',
                      element_action: 'click button',
                      element_name: 'Abrir conta digital',
                      redirect_url: 'https://inter-co.onelink.me/Qyu7/lhth6zd9',
                      })
                  }}
                > Abrir conta digital
                </a>
                ) : (
                  <Button
                    onClick={() => {
                      setIsOpenAccount(true)
                      sendDatalayerEvent(dataLayer)
                      setDataLayer(dataLayer)
                    }}
                    text='Abrir conta digital'
                  />)}
          </div>
        </div>
      </div>
    </S.StillDontHaveYourInterDigitalAccountSection>
  )
}

export default StillDontHaveYourInterDigitalAccount
