import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'

import { Modal } from 'src/components/Modal'
import AccountModal from 'src/components/UI/Forms/OpenAccountRightForm'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

import useDomReady from 'src/hooks/window/useDomReady'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import QrCode from './assets/images/qr_code_site_contadigital_portabilidadedesalario.png'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

import {
  Hero,
  HowPortabilityWorks,
  AdvantagesOfBringingYourSalaryToInter,
  HowToMakePortabilityToInter,
  StillDontHaveYourInterDigitalAccount,
  PlatinumCardPointsPersonalizedService,
  RelatedContent,
  Faq,
} from './_sections/_index'

const SalaryPortability = () => {
  const [ isOpenAccount, setIsOpenAccount ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false)

  const domReady = useDomReady()

  const modalOpen = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Acesse o <a target="_blank" rel="noreferrer" href="https://intergo.app/f650449c" class="text-orange--extra">Super App</a> e faça a portabilidade agora!'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para a loja de aplicativos. Aí é só baixar o app do Inter.'
        subtitle='Traga seu salário para o Inter e aproveite todos os benefícios que só o nosso Super App pode oferecer.'
        qrCode={QrCode}
      />
    </Modal>
  )

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Portabilidade de Salário no Inter: a liberdade de uma conta digital gratuita',
    element_action: 'click button',
    element_name: 'Abrir conta',
  })

  const modalAccountOpen = domReady && (
    <Modal isModalOpen={isOpenAccount} setIsModalOpen={setIsOpenAccount} locationToRender={document.body}>
      <AccountModal closeModal={() => setIsOpenAccount(false)} dataLayer={dataLayer} />
    </Modal>
  )

  return (
    <Wrapper>
      {modalAccountOpen}
      {modalOpen}
      <Layout pageContext={pageContext}>
        <Hero setIsOpenAccount={setIsOpenAccount} setIsOpenQRCode={setIsOpen} setDataLayer={setDataLayer} />
        <HowPortabilityWorks setIsOpenQRCode={setIsOpen} />
        <AdvantagesOfBringingYourSalaryToInter />
        <HowToMakePortabilityToInter setIsOpenQRCode={setIsOpen} />
        <StillDontHaveYourInterDigitalAccount setIsOpenAccount={setIsOpenAccount} setDataLayer={setDataLayer} />
        <PlatinumCardPointsPersonalizedService />
        <RelatedContent />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default SalaryPortability
