import React, { useState, useLayoutEffect } from 'react'
import * as S from './style'
import CompoundCarousel from './CompoundCarousel/_CompoundCarousel'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import Link from '../../_componentes/_link/_index'
import { WIDTH_MD } from 'src/styles/breakpoints'
import Button from '../../_componentes/_button/_index'
import carouselContent from './assets/data.json'
interface IHowToMakePortabilityToInterProps {
  setIsOpenQRCode: Function;
}

export const HowToMakePortabilityToInter = ({ setIsOpenQRCode }: IHowToMakePortabilityToInterProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isMobile, setIsMobile ] = useState(true)
  const width = useWidth(300)

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <S.HowToMakePortabilityToInterSection className='default-padding d-flex align-items-center'>
      <div className='container'>
        <div className='row'>
          <div className='d-none d-md-block col-md-12'>
            <h2 className='fs-24 lh-28 fs-md-30 lh-md-36 fs-xl-40 lh-xl-48 fw-700 text-grayscale--500 font-citrina text-xl-center'>Como fazer portabilidade para o Inter?</h2>
            <p className='fs-14 lh-17 fs-md-18 lh-md-22 fw-400 text-grayscale--500 font-inter text-xl-center'>Em poucos cliques você traz o seu salário pro Inter. É tudo no Super App e sem pagar nada. </p>
          </div>
          <CompoundCarousel carouselContent={carouselContent}>
            <div className='col-12 col-md-6'>
              <CompoundCarousel.ImageCarousel />
            </div>
            <div className='col-12 col-md-6'>
              <div className='my-4 d-md-none'>
                <h2 className='fs-24 lh-28 fs-md-30 lh-md-36 fs-xl-40 lh-xl-48 fw-700 text-grayscale--500 font-citrina'>Como fazer portabilidade para o Inter?</h2>
                <p className='fs-14 lh-17 fs-md-18 lh-md-22 fw-400 text-grayscale--500 font-inter'>Em poucos cliques você traz o seu salário pro Inter. É tudo no Super App e sem pagar nada. </p>
              </div>
              <div className='d-flex justify-content-between mt-4'>
                <CompoundCarousel.CarouselStepper />
                <CompoundCarousel.ButtonGroup />
              </div>
              <CompoundCarousel.TextContentCarousel />
              {
                isMobile ? (
                  <Link
                    href='https://inter-co.onelink.me/Qyu7/1015tqmv'
                    className='mb-3 mt-4'
                    text='Trazer salário para o Inter'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_04',
                        section_name: 'Como fazer portabilidade para o Inter?',
                        element_action: 'click button',
                        element_name: 'Trazer salário para o Inter',
                        redirect_url: 'https://inter-co.onelink.me/Qyu7/1015tqmv',
                       })
                    }}
                  />
                  ) : (
                    <Button
                      onClick={() => {
                        setIsOpenQRCode(true)
                        sendDatalayerEvent({
                          section: 'dobra_04',
                          section_name: 'Como fazer portabilidade para o Inter?',
                          element_action: 'click button',
                          element_name: 'Trazer salário para o Inter',
                        })
                      }}
                      text='Trazer salário para o Inter'
                      className='mb-3 mt-4'
                    />
                )
              }
            </div>
          </CompoundCarousel>
        </div>
      </div>
    </S.HowToMakePortabilityToInterSection>
  )
}

export default HowToMakePortabilityToInter
